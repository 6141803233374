<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat color="bg_section">
        <v-toolbar-title>Crea Nuovo Cliente</v-toolbar-title>
      </v-toolbar>
      <v-divider />
      <v-card-text>
        <CustomersForm :title="null" @submitted="onSubmitted" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CustomersForm from '@components/customers/CustomersForm.vue'
import { mapMutations } from 'vuex'
export default {
  page: {
    title: 'Crea nuovo',
  },
  name: 'CustomerCreate',
  components: { CustomersForm },
  beforeDestroy() {
    this.reset()
  },
  methods: {
    ...mapMutations('customers', {
      reset: 'RESET_CURRENT',
    }),
    onSubmitted(id) {
      this.$router.push({ name: 'customers_detail', params: { id } })
    },
  },
}
</script>
