<template>
  <Form :title="title" :loading="loading" @submit="onSubmit" @reset="onReset">
    <v-row>
      <FormItem
        v-model="type"
        :rules="{ required: !file }"
        type="select"
        :values="types"
        name="Tipo di Cliente"
        :disabled="!!file"
        :md="4"
        :lg="4"
        :xl="2"
      />

      <FormItem
        v-model="common_name"
        name="Ragione Sociale"
        :rules="{ required: !file && type !== 'consumer' }"
        :disabled="type === 'consumer' || !!file"
        :md="4"
        :lg="4"
        :xl="4"
      />

      <FormItem
        v-model="piva_cfisc"
        name="Partita IVA / Codice Fiscale"
        :disabled="!!file"
        :md="4"
        :lg="4"
        :xl="3"
      />
    </v-row>

    <h4 class="my-3">Referente</h4>
    <v-row>
      <FormItem
        v-model="last_name"
        name="Cognome"
        :rules="{ required: !file }"
        :disabled="!!file"
        :md="4"
        :lg="4"
        :xl="4"
      />
      <FormItem
        v-model="first_name"
        name="Nome"
        :rules="{ required: !file }"
        :disabled="!!file"
        :md="4"
        :lg="4"
        :xl="4"
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="email"
        name="Email"
        type="email"
        :rules="{ required: !file, email: true }"
        :disabled="!!file"
        :md="4"
        :lg="4"
        :xl="3"
      />
      <FormItem
        v-model="work_phone_number"
        name="Telefono"
        rules="phone"
        :disabled="!!file"
        :md="4"
        :lg="4"
        :xl="3"
      />
      <FormItem
        v-model="mobile_phone_number"
        name="Cellulare"
        rules="phone"
        :disabled="!!file"
        :md="4"
        :lg="4"
        :xl="3"
      />
    </v-row>

    <h4 class="my-3">Indirizzo</h4>

    <v-row>
      <v-col :cols="12" :sm="8" :md="8" :lg="4">
        <v-row>
          <FormAddress
            v-if="!edit || !manual"
            v-model="address"
            name="Indirizzo"
            :disabled="!!file"
            :cols="9"
            :sm="9"
            :md="9"
            :lg="9"
            :xl="9"
            @address="setCurrentValues"
          />
          <FormItem
            v-else
            v-model="address"
            name="Indirizzo"
            :disabled="!!file"
            :cols="9"
            :sm="9"
            :md="9"
            :lg="9"
          />
          <v-col cols="3">
            <v-switch
              v-model="manual"
              class="manual-address"
              :label="'Manuale'"
            />
          </v-col>
        </v-row>
      </v-col>
      <FormItem
        v-model="address_2"
        name="Indirizzo (continua)"
        :disabled="!!file"
        :cols="12"
        :sm="4"
        :md="4"
      />
    </v-row>
    <v-row>
      <FormRegions v-model="region" :disabled="!!file" />
      <FormProvince v-model="province" :regions="region" :disabled="!!file" />
      <FormItem v-model="city" name="Città" :disabled="!!file" />
      <FormItem
        v-model="cap"
        name="CAP"
        :disabled="!!file"
        :cols="2"
        :sm="3"
        :md="2"
        :lg="1"
      />
      <FormItem
        v-model="lat"
        name="Lat"
        :disabled="!!file"
        :cols="2"
        :sm="3"
        :md="2"
        :lg="2"
        :xl="2"
      />
      <FormItem
        v-model="lng"
        name="Long"
        :disabled="!!file"
        :cols="2"
        :sm="2"
        :md="2"
        :lg="2"
        :xl="2"
      />
    </v-row>
    <h4 class="my-3">Email Notifiche Ticket</h4>
    <v-row>
      <v-col cols="12" :md="7" :lg="6" :xl="7">
        <ChipsSelect
          v-model="distribution_list"
          label="Indirizzi email"
          hint="Inserici gli indirizzi ai quali verrà inviata una notifica in caso di nuovi ticket."
          :rules="{ required: false, email: true }"
        />
      </v-col>
    </v-row>
    <h4 class="my-3">Note</h4>
    <v-row>
      <FormItem
        v-model="notes"
        name="note"
        type="textarea"
        :disabled="!!file"
        :cols="12"
        :sm="12"
        :md="6"
        :lg="6"
        :xl="6"
      />
    </v-row>
    <v-divider v-if="!edit && canUser('customers', 'import')" class="my-6" />
    <v-row v-if="!edit && canUser('customers', 'import')">
      <v-col>
        <p>
          E' possibile caricare massivamente più clienti procedendo al
          caricamento multiplo.
          <br />
          <a href="/static/templates/customers_import_template.xlsx">Scarica</a>
          il template personalizzato e caricalo dopo aver riempito i campi
          necessari.
        </p>
      </v-col>
      <v-col cols="5">
        <v-file-input
          v-model="file"
          label="Import massivo XLS"
          accept="application/vnd.ms-excel || application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          rule="size:100"
          show-size
          filled
          clearable
        ></v-file-input>
      </v-col>
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapState } from 'vuex'
import { authComputed } from '@state/helpers.js'

import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormRegions from '@components/forms/FormRegions.vue'
import FormProvince from '@components/forms/FormProvince.vue'
import FormAddress from '@components/forms/FormAddress.vue'
import ChipsSelect from '@components/forms/ChipsSelect.vue'

const { mapFields } = createHelpers({
  getterType: 'customers/getCurrent',
  mutationType: 'customers/SET_CURRENT_FIELDS',
})

export default {
  name: 'CustomersForm',
  components: {
    FormAddress,
    FormProvince,
    FormRegions,
    FormItem,
    Form,
    ChipsSelect,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
    onsubmitted: {
      type: Function,
    },
  },
  data() {
    return {
      manual: false,
    }
  },
  computed: {
    ...authComputed,
    ...mapFields([
      'common_name',
      'first_name',
      'last_name',
      'type',
      'email',
      'mobile_phone_number',
      'work_phone_number',
      'alt_phone_number',
      'alt_phone_number_2',
      'piva_cfisc',
      'address',
      'address_2',
      'city',
      'region',
      'province',
      'cap',
      'country',
      'lat',
      'lng',
      'notes',
      'file',
      'distribution_list',
    ]),
    ...mapState('customers', {
      id: (state) => state.current.id,
      types: (state) => state.types,
      loading: (state) => state.loading,
    }),
  },
  methods: {
    ...mapMutations('customers', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapActions('customers', [
      'create',
      'update',
      'createBulk',
      'setCurrent',
    ]),
    async onSubmit() {
      try {
        this.$emit('submit')
        if (this.file) {
          await this.createBulk()
          this.$emit('submitted')
          this.reset()
        } else {
          let data
          if (!this.edit) {
            data = await this.create()
          } else {
            data = await this.update()
          }

          this.onsubmitted
            ? this.onsubmitted(data.id)
            : this.$emit('submitted', data.id)
        }
      } catch (err) {
        console.log('errore', err)
      }
    },
    onReset() {
      this.reset()
      this.$emit('resetted')
    },
  },
}
</script>

<style lang="scss">
.manual-address {
  // Make the switch vertical
  .v-input__slot {
    flex-wrap: wrap;
    top: -15px;
    position: relative;

    > * {
      flex: 1 1 100%;
    }

    .v-input--selection-controls__input {
      order: 2;
    }
  }
}
</style>
