<template>
  <FormItem
    :clearable="clearable"
    :dense="dense"
    :disabled="disabled"
    :hint="hint"
    :label="label || name"
    :name="name"
    :persistent-hint="persistentHint"
    :rules="rules"
    :type="multi ? 'autocomplete' : 'select'"
    :value="value"
    :values="provinces"
    :cols="cols"
    :sm="sm"
    :md="md"
    :lg="lg"
    :xl="xl"
    @input="onInput"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import FormItem from '@components/forms/FormItem.vue'
import { debounce, isString } from 'lodash'

export default {
  name: 'FormProvince',
  components: { FormItem },
  props: {
    clearable: {
      default: false,
      type: Boolean,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hint: {
      default: null,
      required: false,
      type: String,
    },
    label: {
      default: null,
      required: false,
      type: String,
    },
    cols: {
      default: 12,
      type: [String, Number],
    },
    sm: {
      default: 6,
      type: [String, Number],
    },
    md: {
      default: 3,
      type: [String, Number],
    },
    lg: {
      default: 3,
      type: [String, Number],
    },
    xl: {
      default: 3,
      type: [String, Number],
    },
    multi: {
      default: false,
      type: Boolean,
    },
    name: {
      default: 'Provincia',
      type: String,
    },
    persistentHint: {
      default: false,
      type: Boolean,
    },
    regions: {
      type: [String, Array],
      default() {
        return []
      },
    },
    rules: {
      default: null,
      required: false,
      type: [String, Object],
    },
    value: {
      default: null,
      type: [String, Array],
    },
  },
  data: () => ({
    model: null,
  }),
  computed: {
    ...mapGetters('utils', ['provincesByRegions']),
    provinces() {
      return this.provincesByRegions(this.regions)
    },
  },
  watch: {
    regions() {
      // When the allowed regions change, if there's no value do nothing
      if (!this.value) return

      const validValues = this.provinces.map((p) => p.value)
      // If value is an array keep only the values that are still allowed
      if (Array.isArray(this.value)) {
        const stillValid = this.value.filter((v) => validValues.includes(v))
        this.$emit('input', stillValid)
      } else {
        // If it's a string check if it's still valid and nullify if not
        if (isString(this.value)) {
          if (!validValues.includes(this.value)) {
            this.$emit('input', null)
          }
        }
      }
    },
  },
  methods: {
    onInput: debounce(function (value) {
      this.$emit('input', value)
    }, 250),
  },
}
</script>
