<template>
  <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
      <v-autocomplete
        v-model="selected"
        :clearable="clearable"
        :dense="dense"
        :disabled="disabled"
        :hint="hint"
        :label="label || name"
        :name="name"
        :persistent-hint="persistentHint"
        :loading="loading"
        :items="items"
        :search-input.sync="query"
        :error-messages="errors"
        filled
        item-text="address_line1"
        no-filter
        return-object
        autocomplete="new-password"
        @input="onInput"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.address_line1" />
            <v-list-item-subtitle v-text="item.address_line2" />
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-map-marker</v-icon>
          </v-list-item-action>
        </template>
      </v-autocomplete>
    </ValidationProvider>
  </v-col>
</template>

<script>
import { netRequest } from '@api/client.js'
import { debounce } from 'lodash'

export default {
  name: 'FormAddress',
  components: {},
  props: {
    rules: {
      default: '',
      required: false,
      type: [String, Object],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    name: {
      type: String,
      default: 'Indirizzo',
    },
    cols: {
      type: [String, Number],
      default: 12,
    },
    sm: {
      type: [String, Number],
      default: 6,
    },
    md: {
      type: [String, Number],
      default: 3,
    },
    lg: {
      type: [String, Number],
      default: 4,
    },
    xl: {
      type: [String, Number],
      default: 3,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Array],
      default: null,
    },
  },
  data: () => ({
    selected: null,
    loading: false,
    query: null,
    items: [],
  }),
  watch: {
    query(...args) {
      this.debouncedWatch(...args)
    },
  },
  created: function() {
    this.debouncedWatch = debounce((text) => {
      this.searchAddress(text)
    }, 500)
  },
  beforeUnmount() {
    this.debouncedWatch.cancel()
  },
  methods: {
    searchAddress(text) {
      try {
        if (!text) return

        if (
          this.selected &&
          typeof this.selected === 'object' &&
          this.selected.address_line1 === text
        )
          return

        if (this.selected && this.selected === text) return

        this.loading = true

        netRequest('POST', 'utils/geocoding/autocomplete', {
          text,
        }).then((data) => (this.items = data))
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },

    onInput(place) {
      if (!place) {
        this.$emit('input', null)
        return
      }

      this.$emit('input', place.address_line1)

      this.$emit('address', {
        city: place.city,
        country: place.country,
        cap: place.postcode,
        address: place.address_line1,
        region: place.state,
        province: place.county_code,
        lat: place.lat,
        lng: place.lon,
      })
    },
  },
}
</script>
